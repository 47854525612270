<template>
  <body class="scrll">
    <div class="wrapper">
      <div class="over landing"></div>
      <div v-if="!loaded" class="loader">
        <div class="ld">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <Header ref="header" />
      <section class="main">
        <div class="container">
          <div class="text__holder">
            <h1>
              {{
                {
                  es: "Artículos coleccionables de tus influencers favoritos!",
                  en: "Collectible items of your favorite influencers!",
                }[lang]
              }}
            </h1>
            <h2>
              {{
                {
                  es: "Ubilong es la nueva plataforma de coleccionables Licenciados! Es la nueva forma de contribuír con tus artistas favoritos, completá colecciones o conseguí los más exóticos para obtener contenído exclusivo!",
                  en: "Ubilong is the new platform for licensed collectibles! It's the new way to contribute with your favorite artists, complete collections or get the most exotic ones to get exclusive content!",
                }[lang]
              }}
            </h2>
            <a href="/boosters">{{
              { en: "Get started!", es: "Comenzar!" }[lang]
            }}</a>
          </div>
          <div class="prop">
            <div
              @mouseenter="mouseOver($event, card)"
              @mouseleave="mouseOut($event, card)"
              v-for="card in cards"
              :key="card.url"
              class="card"
            >
              <div class="media__holder">
                <transition name="fade">
                  <img
                    :class="{ booster__img: true, taped: card.taped }"
                    v-if="!card.playing"
                    :src="card.preview"
                  />
                  <video
                    :class="{ booster__img: true, taped: card.taped }"
                    v-else
                    :src="card.url"
                    autoplay
                    loop
                    muted
                  ></video>
                </transition>
              </div>
              <div class="lip">
                <span>{{ card.name }}</span>
                <button v-if="card.href" @click="goTo(card.href)">
                  <img src="/img/booster-arrow.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="partners" class="partners">
        <div class="txt__holder">
          <h1>
            {{
              {
                es: "Dona a tus streamers favoritos",
                en: "Daonte to your favourite streamers",
              }[lang]
            }}
          </h1>
          <h2>
            {{
              {
                es: "Es muy fácil, elegis el streamer al que quieras donarle y recibis cartas coleccionables a cambio de tu aporte!",
                en: "It's very easy, choose the streamer you want to donate to and receive collectible cards in exchange for your contribution!",
              }[lang]
            }}
          </h2>
        </div>
        <div class="slider__back">
          <div class="slide__bar">
            <div v-if="partners" class="slider">
              <div
                class="p__pack default"
                v-for="partner in partners"
                :key="partner"
                @click="goTo(partner.href)"
              >
                <img
                  :alt="partner.alt"
                  :src="
                    partner.field
                      ? './img/partners/partner_ubilong.jpg'
                      : partner.url
                  "
                />
                <a class="open__btn">
                  <svg viewBox="0 0 512 512">
                    <path
                      fill="currentColor"
                      d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div v-else class="slider">
              <div
                v-for="partner in partnerPage"
                :key="partner"
                data-load="true"
                style="
                  width: 260px;
                  height: 260px;
                  border-radius: 20px;
                  margin: 0 20px;
                  background-color: rgb(43, 43, 43);
                "
              ></div>
            </div>
          </div>
          <div v-if="partners?.length" class="buttons">
            <div
              v-for="button in partnerRenders"
              :key="button"
              @click="slide(button)"
              :class="{
                slide__button: true,
                current__slide: currentSlide == button,
              }"
            ></div>
          </div>
        </div>
      </section>
      <section class="benefits">
        <div class="benefits__slider">
          <div @click="goTo('market')" class="block">
            <div class="img__holder">
              <img src="/img/b2.png" />
            </div>
            <div class="description">
              <h1>
                {{
                  {
                    es: "Intercambia cartas y completa tus colecciones",
                    en: "Trade cards and complete your collections",
                  }[lang]
                }}
              </h1>
              <p>
                {{
                  {
                    es: "Con Ubilong puedes recopilar los momentos, el arte y los recuerdos de tus streamers favoritos. Cartas intercambiables con tus amigos para completar nuestras colecciones exclusivas y participar de grandes premios.",
                    en: "With Ubilong you can collect your favourite streamers moments, art and memories. Trade cards with your friends to complete our exclusive collections and participate for great prizes.",
                  }[lang]
                }}
              </p>
            </div>
          </div>
          <div class="block">
            <div class="img__holder">
              <img src="/img/b3.png" />
            </div>
            <div class="description">
              <h1>
                {{
                  {
                    es: "Conviértete en un partner!",
                    en: "Become a Partner!",
                  }[lang]
                }}
              </h1>
              <p>
                {{
                  {
                    es: "Ubilong es la herramienta perfecta para Streamers, Youtubers y otros creadores de contenido para vender su merchandising digital. Todas nuestras colecciones son artículos exclusivos y con licencia creados junto con los artistas. ¡Únete a nosotros!.",
                    en: "Ubilong is the perfect tool for Streamers, Youtubers and other content creators to sell their digital merchandising. All our collections are exclusive and licensed articles created together with the artists. Join us!.",
                  }[lang]
                }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer ref="footer" />
      <Modals ref="modals" />
    </div>
  </body>
</template>

<script>
import Footer from "../../layout/Footer.vue";
import Header from "../../layout/Header.vue";
import Modals from "../../components/Modals.vue";

export default {
  data: function () {
    return {
      name: "home",
      user: null,
      acTok: null,
      page: 1,
      cards: /* [
        {
          url: "https://ubilong.com/img/boosters/Blue0001-01501v2.webm",
          preview: "https://ubilong.com/preview/Blue0001-01501.png",
          name: "Soon",
        },
        {
          url: "https://ubilong.com/img/boosters/Black0001-0150v2.webm",
          preview: "https://ubilong.com/preview/Black0001-0150.png",
          name: "Soon",
        },
        {
          url: "https://ubilong.com/img/boosters/BOOSTERGOLD0001-0150v2.webm",
          preview: "https://ubilong.com/preview/BOOSTERGOLD0001-0150.png",
          name: "Soon",
        },
      ] ||  */ [
        {
          url: "https://ubilong.com/img/boosters/Gold0001-0150v2.webm",
          preview: "https://ubilong.com/preview/Gold0001-0150.png",
          href: "https://ubilong.com/boosters?page=1&sortBy=rarityHash:desc&search=gold",
          name: "Gold Boosters",
        },
        {
          url: "https://ubilong.com/img/boosters/Blue0001-01502v2.webm",
          preview: "https://ubilong.com/preview/Blue0001-01502.png",
          href: "https://ubilong.com/boosters?page=1&sortBy=rarityHash:desc&search=blue",
          name: "Blue Boosters",
        },
        {
          url: "https://ubilong.com/img/boosters/BOOSTERBLACK0001-0150v2.webm",
          preview: "https://ubilong.com/preview/BOOSTERBLACK0001-0150.png",
          href: "https://ubilong.com/boosters?page=1&sortBy=rarityHash:desc&search=black",
          name: "Black Boosters",
        },
      ],
      partnerPage: 3,
      currentSlide: 1,
      partnersWidth: 280,
      partnerRenders: null,
      partners: null,
      loading: true,
      endpoint: "https://ubilong.com/api/v1",
      loaded: false,
      lang: "es",
    };
  },
  components: {
    Modals,
    Footer,
    Header,
  },
  methods: {
    mouseOver: function (e, n) {
      if (n.playing) return;
      n.playing = true;
    },
    mouseOut: function (e, n) {
      setTimeout(() => {
        if (!n.playing) return;
        n.playing = false;
      }, 100);
    },
    languageSwap: function () {
      this.lang = this.lang == "es" ? "en" : "es";
      document.cookie = `lang=${this.lang}`;
    },
    goTo: function (url) {
      window.location = url;
    },
    formatDate: function (end = 1656705600000) {
      let now = new Date();
      let offset = now.getTimezoneOffset() / 60;
      end = new Date(end + offset * 60 * 60 * 1000);
      let rem = end - now;
      if (rem <= 0) return { d: "👀", h: "👀", m: "👀", s: "👀" };
      let days = Math.floor(rem / (1000 * 60 * 60 * 24));
      let hours = Math.floor((rem % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((rem % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((rem % (1000 * 60)) / 1000);
      return { d: days, h: hours, m: minutes, s: seconds };
    },
    slide: function (btn) {
      if (this.partnerPage == btn) return;
      let slide = btn - 1;
      let el = document.querySelector(".partners .slider__back .slider");
      this.partnerPage = btn;
      this.currentSlide = btn;
      el.style.left = `-${slide * this.partnersWidth}px`;
    },
    fourthHandler: function (state) {
      const el = document.querySelector(".partners .slider__back .slider");
      if (state?.matches) {
        this.partnerRenders = +this.partners?.length - 3 || 1;
        this.partnerPage = 4;
        this.currentSlide = 1;
        this.partnersWidth = 280;
        if (el) el.style.left = `0px`;
      }
    },
    thirdHandler: function (state) {
      const el = document.querySelector(".partners .slider__back .slider");
      if (state?.matches) {
        this.partnerRenders = +this.partners?.length - 2 || 2;
        this.partnerPage = 3;
        this.currentSlide = 1;
        this.partnersWidth = 280;
        if (el) el.style.left = `0px`;
      }
    },
    firstHandler: function (state) {
      const el = document.querySelector(".partners .slider__back .slider");
      if (state?.matches) {
        this.partnerRenders = +this.partners?.length - 1 || 2;
        this.partnerPage = 2;
        this.currentSlide = 1;
        this.partnersWidth = 280;
        if (el) el.style.left = `0px`;
      }
    },
    secondHandler: function (state) {
      const el = document.querySelector(".partners .slider__back .slider");
      if (state?.matches) {
        this.partnerRenders = +this.partners?.length || 1;
        this.partnerPage = 1;
        this.currentSlide = 1;
        this.partnersWidth = 280;
        if (el) el.style.left = `0px`;
      }
    },
    getPrt: function () {
      let first = window.matchMedia(
        "(max-width: 1250px) and (min-width: 700px)"
      );
      let third = window.matchMedia(
        "(max-width: 1450px) and (min-width: 1250px)"
      );
      let fourth = window.matchMedia("(min-width: 1450px)");
      let second = window.matchMedia("(max-width: 700px) and (min-width: 0px)");
      this.firstHandler(first);
      this.secondHandler(second);
      this.thirdHandler(third);
      this.fourthHandler(fourth);
      return fetch(`${this.endpoint}/assets/partners?sortBy=index:asc`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (rawResponse) => {
          if (rawResponse.ok) {
            let response = await rawResponse.json();
            this.partners = response.results;
            this.firstHandler(first);
            this.secondHandler(second);
            this.thirdHandler(third);
            this.fourthHandler(fourth);
            first.addEventListener("change", this.firstHandler);
            third.addEventListener("change", this.thirdHandler);
            fourth.addEventListener("change", this.fourthHandler);
            second.addEventListener("change", this.secondHandler);
          } else {
            const contentType = rawResponse.headers.get("content-type");
            let response = contentType.includes("application/json")
              ? await rawResponse.json()
              : await rawResponse.text();
            if (this.$refs.modals)
              this.$refs.modals.new(response.message || response);
          }
        })
        .catch((e) => {
          if (this.$refs.modals) this.$refs.modals.new(e.message);
        });
    },
    checkPayment: function (token) {
      return fetch(`${this.endpoint}/orders/paypal/capture/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.acTok}`,
        },
      })
        .then(async (rawResponse) => {
          if (rawResponse.ok) {
            let response = await rawResponse.json();
            if (this.$refs.modals)
              this.$refs.modals.new(
                `${+response.order.value.toFixed(2)} USD ${
                  {
                    es: "añadidos con éxito!",
                    en: "added with success!",
                  }[this.lang]
                }`,
                true
              );
            this.$refs.header.user.balance = +this.$refs.header.user.balance
              ? +this.$refs.header.user.balance + +response.order.value
              : +response.order.value;
            window.history.pushState(
              {},
              document.title,
              window.location.pathname
            );
          } else {
            const contentType = rawResponse.headers.get("content-type");
            let response = contentType.includes("application/json")
              ? await rawResponse.json()
              : await rawResponse.text();
            if (this.$refs.modals)
              this.$refs.modals.new(response.message || response);
          }
        })
        .catch((e) => {
          if (this.$refs.modals) this.$refs.modals.new(e.message);
        });
    },
    rCok: (n) => {
      let name = n + "=";
      let ca = decodeURIComponent(document.cookie).split(";");
      for (let i = 0; i < ca.length; i++) {
        while (ca[i].charAt(0) == " ") ca[i] = ca[i].substring(1);
        if (ca[i].indexOf(name) == 0)
          return ca[i].substring(name.length, ca[i].length);
      }
      return "";
    },
    rQry: function (v) {
      var vars = [
        ...window.location.search?.substring(1).split("&"),
        ...window.location.hash?.substring(1).split("&"),
      ];
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) == v) {
          return decodeURIComponent(pair[1]);
        }
      }
      return "";
    },
  },
  created() {
    document.addEventListener("click", this.doClick);
    this.getPrt();
  },
  mounted() {
    this.$gtag.event("login", { method: "Google" });
    this.lang = this.rCok("lang") || "es";
    this.$refs.footer.lang = this.lang;
    this.acTok = this.$refs.header.acTok;
    const [paypalId, orderId] = [this.rQry("token"), this.rQry("PayerID")];
    if (paypalId && orderId) {
      this.checkPayment(paypalId);
    }
    this.intrval = setInterval(() => {
      if (!this.$refs?.d) return;
      const { d, h, m, s } = this.formatDate();
      this.$refs.d.textContent = `${d}d`;
      this.$refs.h.textContent = `${h}h`;
      this.$refs.m.textContent = `${m}m`;
      this.$refs.s.textContent = `${s}s`;
    }, 100);
    this.$refs.header.headerName = this.name;
    this.user = this.$refs.header.user;
    this.$refs.header.lang = this.lang;
    this.$refs.header.setUser = (user) => {
      this.user = user;
    };
    this.$refs.header.modal = (modal) => {
      if (this.$refs.modals)
        this.$refs.modals.new(
          modal.description[this.lang],
          modal.type == "info" ? true : false
        );
    };
    this.$refs.header.swap = (lang) => {
      this.lang = lang;
      this.$refs.footer.lang = lang;
      this.$refs.header.lang = lang;
    };
    this.loaded = true;
  },
};
</script>

<style scoped>
@import "../../assets/home.css";
</style>
